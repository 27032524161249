import React, { useState } from "react";

import { RiArrowDownSLine } from "react-icons/ri";

import SideProfileInfo from "./SideProfileInfo";
import Information from "./Information";
import SocialMedia from "./SocialMedia";

const LineSeperator = ({ toogleContact }) => {
    return (
        <div
            className={`
            lg:opacity-100 mt-5 rounded-3xl bg-opacity-50 w-full mx-auto h-0.5 bg-white transition-all duration-500
            ${toogleContact === false ? "opacity-0" : ""}`}
        ></div>
    );
};

const SideProfile = () => {
    const [toogleContact, setToogleContact] = useState(false);
    const TootleContactState = () => {
        setToogleContact(!toogleContact);
    };
    return (
        <div
            className="
            transition-all 
            duration-500
            w-full 
            min-w-[270px]
            max-w-full 
            lg:max-w-[300px] 
            bg-opacity-40 
            bg-gray-600 border 
            border-gray-400 
            rounded-3xl 
            text-center 
            p-10 overflow-hidden py-5
            h-fit relative lg:sticky lg:top-10"
        >
            <div
                onClick={TootleContactState}
                className="lg:hidden absolute top-0 right-0 w-13 bg-slate-600 bg-opacity-70 cursor-pointer p-2 border border-white text-white rounded-tr-2xl rounded-bl-2xl"
            >
                <RiArrowDownSLine
                    size={25}
                    className={` 
                    ${toogleContact === true ? "-rotate-180" : ""} 
                    transition-all duration-500`}
                />
            </div>
            <SideProfileInfo />
            <LineSeperator toogleContact={toogleContact} />
            <div
                className={`${
                    toogleContact === false
                        ? "max-h-0 opacity-0"
                        : "max-h-screen "
                } 
                    transition-all duration-700 lg:max-h-full  lg:opacity-100`}
            >
                <Information />
                <LineSeperator toogleContact={toogleContact} />
                <SocialMedia />
            </div>
        </div>
    );
};
export default SideProfile;
