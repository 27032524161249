import React from "react";

import SectionTitle from "../Common/SectionTitle";
import Subtitle from "../Common/SubTitle";
import DoingContainer from "./ContainerComponent/DoingContainer";

import AboutData from "../../Data/AboutData";

const About = () => {
    const { Descriptions, Doings } = AboutData;
    return (
        <>
            <SectionTitle SectionName="About" />
            <div className="Description">
                {Descriptions.map((Description) => {
                    return (
                        <p className="mt-5" key={Description.key}>
                            {Description.text}
                        </p>
                    );
                })}
            </div>
            <div>
                <Subtitle SubtitleText="What I'm Doing" />
                <div className="mt-5 md:grid grid-cols-2 gap-4">
                    {Doings.map((doing) => {
                        return <DoingContainer key={doing.key} doing={doing} />;
                    })}
                </div>
            </div>
        </>
    );
};

export default About;
