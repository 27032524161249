import React from "react";

import TimelineContainer from "./ContainerComponent/TimelineContainer";
import SectionTitle from "../Common/SectionTitle";
// import SkillContainer from "./ContainerComponent/SkillContainer";

import ResumesData from "../../Data/ResumesData";
// import SubTitle from "../Common/SubTitle";

const Resume = () => {
    return (
        <>
            <SectionTitle SectionName="Resume" />
            <div>
                {ResumesData.map((Resume) => {
                    return (
                        <TimelineContainer key={Resume.key} Resume={Resume} />
                    );
                })}
            </div>
            {/* <div>
                <SubTitle SubtitleText="Skills" />
                <div className="text-left py-4 bg-white bg-opacity-10 p-5 rounded-2xl backdrop-blur-sm border border-white mt-5">
                    <SkillContainer />
                </div>
            </div> */}
        </>
    );
};

export default Resume;
