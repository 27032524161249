import React from "react";

import SocialMediasData from "../../Data/SocialMediasData";

const SocialMedia = () => {
    return (
        <div className="justify-center flex mx-auto text-left mt-5">
            {SocialMediasData.map((socialMedia) => {
                return (
                    <a
                        href={socialMedia.Link}
                        rel="noreferrer"
                        target="_blank"
                        className="mr-3 text-white hover:text-slate-300"
                        key={socialMedia.key}
                        aria-label={`Visit ${socialMedia.Name}`}
                    >
                        {socialMedia.Icon}
                    </a>
                );
            })}
        </div>
    );
};

export default SocialMedia;
