import React from "react";
import PersonalImage from "../../Assets/PersonalImage.jpg";
import Typewriter from "../Common/TypeWritter";

const SideProfileInfo = () => {
    return (
        <div className="sm:flex lg:block">
            <div className="transition-all duration-500 bg-white rounded-2xl mx-auto sm:mx-0 lg:mx-auto h-[150px] w-[150px] overflow-hidden border-2 border-white">
                <img
                    src={PersonalImage}
                    alt="Personal"
                    className="w-full h-full"
                />
            </div>
            <div className="transition-all duration-500 text-center sm:text-left lg:text-center ml-0 sm:ml-10 lg:ml-0">
                <p className="my-5 text-2xl font-semibold text-white">
                    Natanael Geraldo Sulaiman
                </p>
                <span className="bg-opacity-20 bg-gradient-to-br from-gray-300 to-white p-1 rounded-md text-gray-800 font-medium px-5">
                    <Typewriter
                        strings={["Data Analyst", "Data Scientist", "Fullstack Developer"]}
                        delay={100}
                        infinite
                        pauseDelay={2000}
                    />
                </span>
            </div>
        </div>
    );
};

export default SideProfileInfo;
