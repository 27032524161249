import React from "react";

const DoingContainer = ({ doing }) => {
    const { Icon, Title, Description } = doing;

    return (
        <div className="text-center md:text-left md:flex items-center py-4 bg-white bg-opacity-10 p-5 rounded-2xl backdrop-blur-sm border border-white mt-5 md:mt-0">
            <div className="bg-white p-3 rounded-md w-fit mx-auto md:mx-0 text-Primary-Color">
                {Icon}
            </div>
            <div className="pl-5 overflow-hidden">
                <p className="font-bold mt-3 md:mt-0 text-lg">{Title}</p>
                <p>{Description}</p>
            </div>
        </div>
    );
};

export default DoingContainer;
