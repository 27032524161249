import {
    RiInstagramLine,
    RiLinkedinBoxFill,
    RiGithubFill,
} from "react-icons/ri";
const SocialMediasData = [
    {
        key: "1",
        Name: "Instagram",
        Icon: <RiInstagramLine size={30} />,
        Link: "https://www.instagram.com/natanaelgeraldo_",
    },
    {
        key: "2",
        Name: "Linked In",
        Icon: <RiLinkedinBoxFill size={30} />,
        Link: "https://www.linkedin.com/in/natanael-geraldo-sulaiman-952a561a9",
    },
    {
        key: "3",
        Name: "Github",
        Icon: <RiGithubFill size={30} />,
        Link: "https://github.com/NatanaelGeraldoS",
    },
];
export default SocialMediasData;
