import {
    RiOrganizationChart,
    RiGraduationCapLine,
    RiBriefcase4Line,
} from "react-icons/ri";
const ResumesData = [
    {
        key: "1",
        Icon: <RiBriefcase4Line size={20} />,
        Title: "Experience",
        Datas: [
            {
                key: "4",
                SubTitle: "Programmer - Full Time (Bina Nusantara Group)",
                Company: "IT Division Bina Nusantara Group",
                Year: "October 2023 - Present",
                Description: "I developed and maintained responsive websites and desktop applications using ASP.NET, C#, .NET Core, Visual Basic, and SQL Server, participating in the full development lifecycle and contributing to debugging, troubleshooting, and feature improvements.",
            },
            {
                key: "3",
                SubTitle: "Junior Programmer - Full Time (Bina Nusantara Group)",
                Company: "IT Division Bina Nusantara Group",
                Year: "March 2022 - October 2023",
                Description: "I developed and maintained responsive websites and desktop applications using ASP.NET, C#, .NET Core, Visual Basic, and SQL Server, participating in the full development lifecycle and contributing to debugging, troubleshooting, and feature improvements.",
            },
            {
                key: "2",
                SubTitle: "Scholarship Mentor (Bina Nusantara University)",
                Company: "Binus University",
                Year: "October 2021 - May 2022",
                Description:
                    "Teaching and providing teaching materials for Mentee on several lecture materials such as, Artificial Intelligence, Computer Network, Algorithm Design and Analysis, and Database System",
            },
            {
                key: "1",
                SubTitle: "Associate Member - Part Time (IT Division Bina Nusantara Group)",
                Company: "IT Division Bina Nusantara Group",
                Year: "March 2021 - March 2022",
                Description:
                    "Assist and make necessary adjustments to the Student Desk, Parent Desk, and Staff Desk BINUS SCHOOL Serpong",
            },
        ],
    },
    {
        key: "2",
        Icon: <RiGraduationCapLine size={20} />,
        Title: "Education",
        Datas: [
            {
                key: "1",
                SubTitle: "Bachelor of Science in Computer Science at Bina Nusantara University",
                Company: null,
                Year: "August 2020 - April 2024",
                Description: "GPA: 3.95/4.0",
            }
        ],
    },
    {
        key: "2",
        Icon: <RiOrganizationChart size={20} />,
        Title: "Organization",
        Datas: [
            {
                key: "1",
                SubTitle: "General Manager of Design and Development Commision",
                Company: null,
                Year: "January 2022 - February 2023",
                Description: "I orchestrated the maintenance and optimization of the HIMTI website, led the design team, supervised web development, provided advisory support for event planning, fostered communication between teams, launched event-specific websites, mentored team members, and managed multiple tasks within a dynamic student organization environment.",
            },
            {
                key: "2",
                SubTitle: "Activist of Web Development Division",
                Company: null,
                Year: "March 2021 - January 2022",
                Description:
                    "Being a Web development activist at HIMTI, I was asked to pioneer all websites owned by HIMTI and I helped create new websites that were needed in accordance with the design provided",
            },
            {
                key: "3",
                SubTitle: "Web Developer Coordinator of HISHOT 2021",
                Company: null,
                Year: "April 2021 - November 2021",
                Description:
                    "Being a coordinator at webdev Hishot we have to communicate with the design team and the publication team.",
            },
            {
                key: "4",
                SubTitle: "Web Developer Computer Run",
                Company: null,
                Year: "May 2021 - December 2021",
                Description:
                    "Being a web developer computer run we have to do the design in accordance with the design given",
            },
        ],
    },
];
export default ResumesData;
