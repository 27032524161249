import React from "react";
import PortofoliosData from "../../../Data/PortofoliosData";
import { RiGithubFill, RiLinkM } from "react-icons/ri";
import Badge from "../../Common/Badge";
function PortofolioModal({ id, closePopUp }) {
    const PortofolioData = PortofoliosData.filter((x) => x.key === id)[0];
    if (!PortofolioData) return null;
    const { title, description, technology, type, url, git, status, image } =
        PortofolioData;
    const altImage = "ModalImage-" + title
    return (
        <div
            data-te-modal-init
            className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-gray-900 bg-opacity-90 m-auto flex"
        >
            <div className="max-w-lg min-h-[100px] max-h-screen m-auto rounded-lg p-5 relative bg-Third-Color border-gray-500 border overflow-y-auto overflow-x-hidden">
                <button
                    className="top-5 right-5 absolute bg-gray-300 bg-opacity-20 py-1 px-3 rounded-md hover:bg-opacity-40 transition-all duration-200"
                    onClick={closePopUp}
                >
                    X
                </button>
                {image && <img src={image} alt={altImage} className="rounded-md" />}
                <div className="mt-2">
                    <span className="bg-gray-300 bg-opacity-20 p-1 rounded-md text-white text-sm px-3">
                        {type}
                    </span>
                </div>
                <p className="mt-5 text-xl font-bold">{title}</p>
                <Badge
                    text={status ? "Done" : "On Progress"}
                    bgColor={status ? "bg-green-700" : "bg-yellow-600"}
                />
                <p className="mt-3 text-md">{description}</p>
                <p className="mt-3 font-semibold">Technology :</p>
                <ul>
                    {technology.map((tech) => {
                        return (
                            <li
                                className="inline-block mr-2 mb-2 hover:-translate-y-0.5 transition-all duration-200 mt-4"
                                key={tech}
                            >
                                <span className="bg-gray-300 bg-opacity-20 p-1 rounded-md text-white text-sm px-3">
                                    {tech}
                                </span>
                            </li>
                        );
                    })}
                </ul>
                <div className="mt-3">
                    {git ? (
                        <a
                            className="inline-block mr-2 mb-2 hover:-translate-y-0.5 transition-all duration-200 mt-4"
                            href={git}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <RiGithubFill size={25} />
                        </a>
                    ) : null}
                    {url ? (
                        <a
                            className="inline-block mr-2 mb-2 hover:-translate-y-0.5 transition-all duration-200 mt-4"
                            href={url}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <RiLinkM size={25} />
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default PortofolioModal;
